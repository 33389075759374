<template>
  <div id="app">
    <ThreeCss3d />
  </div>
</template>

<script>
import ThreeCss3d from './components/ThreeCss3d.vue';

export default {
  name: 'App',
  components: {
    ThreeCss3d,
  },
};
</script>

<style>
/* Add global styles if needed */
body{
  margin: 0px;
}
</style>
