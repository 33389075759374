<template>
  <div>
    <div ref="container" id="container"></div>
    <!--div ref="blocker" id="blocker"></div-->
  </div>
</template>

<script>
import * as THREE from 'three';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

export default {
  name: 'ThreeCss3d',
  mounted() {
    this.init(); 
    this.animate();
  },
  methods: {
    init() {
      const container = this.$refs.container;
      //const blocker = this.$refs.blocker;

      this.camera = new THREE.PerspectiveCamera(50, window.innerWidth / window.innerHeight, 1, 5000);
      this.camera.position.set(500, 350, 750);

      this.scene = new THREE.Scene();

      this.renderer = new CSS3DRenderer();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
      container.appendChild(this.renderer.domElement);

      const group = new THREE.Group();
      group.add(this.createElement('https://www.universalpokedex.com', .4, 0, 0, 180, 0, 0, 0));
      group.add(this.createElement('https://www.universalpokedex.com', .4, 180, 0, 0,0, Math.PI / 2, 0));
      group.add(this.createElement('https://www.universalpokedex.com', .4, 0, 0, -180,0, Math.PI, 0));
      group.add(this.createElement('https://www.universalpokedex.com', .4, -180, 0, 0,0, -Math.PI / 2, 0));
      group.add(this.createElement('https://www.universalpokedex.com', .4, 0, 180, 0, -Math.PI / 2, 0, 0));
      group.add(this.createElement('https://www.universalpokedex.com', .4, 0, -180, 0, Math.PI / 2, 0, 0));
      this.scene.add(group);

      // Create the floor
      // Create the floor
      // Create the floor
      const floorDiv = document.createElement('div');
      floorDiv.style.width = '4000px';
      floorDiv.style.height = '4000px';
      floorDiv.style.backgroundColor = 'gray';
      floorDiv.style.position = 'absolute';

      const floorObject = new CSS3DObject(floorDiv);
      floorObject.position.y = -1000; // Position the floor below the elements
      floorObject.rotation.x = -Math.PI / 2; // Rotate the floor to be horizontal
      this.scene.add(floorObject);

      /*this.controls = new TrackballControls(this.camera, this.renderer.domElement);
      this.controls.rotateSpeed = 4;*/
      this.controls = new OrbitControls(this.camera, this.renderer.domElement);
      this.controls.enableDamping = true; // Enable damping (inertia)
      this.controls.dampingFactor = 0.05; // Damping inertia factor
      this.controls.enablePan = false; // Allow panning (default is true)
      this.controls.enableZoom = false; // Allow zooming (default is true)
      this.controls.maxPolarAngle = Math.PI / 2; // Prevent camera from moving below the floor
      this.controls.minPolarAngle =  -Math.PI// /2; // Prevent camera from moving above the horizon
      console.log(this.camera)


      window.addEventListener('resize', this.onWindowResize);

      /*blocker.style.display = 'none';
      this.controls.addEventListener('start', () => {
        blocker.style.display = '';
      });
      this.controls.addEventListener('end', () => {
        blocker.style.display = 'none';
      });*/
    },
    createElement(id, zoom, x, y, z, rx, ry, rz) {
      const div = document.createElement('div');
      /*div.style.zoom = zoom;
      if(zoom!=1){
        y=y/zoom
      }*/
      div.style.width = `360px`;
      div.style.height = `360px`;
      div.style.backgroundColor = '#000';

      const iframe = document.createElement('iframe');
      //iframe.style.zoom = zoom;
      iframe.style.width = `calc(360px / ${zoom})`; // Adjust the width based on zoom
      iframe.style.height = `calc(360px / ${zoom})`; // Adjust the height based on zoom
      iframe.style.border = '0px';
      iframe.style.transform = `scale(${zoom})`;
      iframe.style.transformOrigin = '0 0';
      iframe.src = `${id}`;
      
      div.appendChild(iframe);

      const object = new CSS3DObject(div);
      object.position.set(x, y, z);
      object.rotation.set(rx, ry, rz);  // Use set method for rotations

      return object;
    },
constrainCamera() {
  /*const minY = -190; // Assuming -200 is the height of your floor
  const minX = 400;
  const minZ = 400;
  if (this.camera.position.y <= minY) {
    this.camera.position.y = minY; // Smoothly constrain
  }
  if (this.camera.position.x <= minX) {
    this.camera.position.x = minX; // Smoothly constrain
  }
  if (this.camera.position.z <= minZ) {
    this.camera.position.z = minZ; // Smoothly constrain
  }
  console.log(this.camera.position)*/
  /*const fixedY = 350; // Capture the initial Y position 350, 750
  const fixedZ = 750;
    this.camera.position.y = fixedY; // Prevent vertical movement
    this.camera.position.z = fixedZ; */// Prevent forward/backward movement
  
  },


    onWindowResize() {
      this.camera.aspect = window.innerWidth / window.innerHeight;
      this.camera.updateProjectionMatrix();
      this.renderer.setSize(window.innerWidth, window.innerHeight);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.controls.update();
      this.constrainCamera(); // Add this line
      this.renderer.render(this.scene, this.camera);
      console.log(this.camera)
    },
  },
};
</script>

<style scoped>
body {
  background-color: #ffffff;
}

#blocker {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
